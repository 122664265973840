import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Toaster, ToastBar } from 'react-hot-toast';

import Box from '../Box';
import Text from '../Text';

const renderChildren = (t) => (
	<ToastBar toast={t}>
		{
			({ message, icon }) => {
				return (
					<>
						{icon}
						<Text as={Box} fontSize={2} lineHeight={2} fontWeight="medium">
							{message}
						</Text>
					</>
				)
			} 
		}
	</ToastBar>
);

const Toast = () => {
	const theme = useTheme();
	const toastOptions = useMemo(() => ({
		style: {
			backgroundColor: theme.dark ? theme.colors.border : theme.colors.surface,
			borderRadius: theme.radii[1],
			color: theme.colors.text,
		},
		success: {
			iconTheme: {
				primary: theme.colors.primary,
				secondary: theme.colors.white,
			},
		},
		error: {
			iconTheme: {
				primary: theme.colors.red,
				secondary: theme.colors.white,
			},
		},
	}), [theme]);

	return (
		<Toaster 
			children={renderChildren}
			position="bottom-center"
			toastOptions={toastOptions}
		/>
	)
}

export default Toast;