import React from 'react';
import Icon from '../Icon';

const BrandKitIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 24 24">
		<path className="secondary" d="M20.341 3.65901C19.462 2.78001 18.038 2.78001 17.159 3.65901L3.805 16.998C3.604 17.199 3.47 17.457 3.421 17.738L3.011 20.116C2.922 20.631 3.37 21.077 3.884 20.988L6.234 20.579C6.513 20.53 6.77 20.397 6.97 20.197L20.341 6.84001C21.22 5.96201 21.22 4.53801 20.341 3.65901Z" />
		<path className="primary" d="M14.697 6.115L17.879 9.297L19.977 7.203L16.795 4.021L14.697 6.115Z" />
		<path className="primary" d="M3.261 18.666L3.011 20.116C2.922 20.631 3.37 21.077 3.884 20.988L5.331 20.736L3.261 18.666Z" />
		<path className="primary" d="M14.856 12.356L6.018 3.518C5.328 2.828 4.208 2.828 3.518 3.518C2.828 4.208 2.828 5.328 3.518 6.018L12.357 14.857L14.856 12.356Z" />
		<path className="secondary" d="M16.262 18.005L12.356 14.856L14.856 12.356L18.005 16.262L16.262 18.005Z" />
		<path className="primary" d="M15.766 19.467C14.744 18.445 14.744 16.789 15.766 15.767C16.788 14.745 18.444 14.745 19.466 15.767C20.085 16.386 20.634 18.294 20.97 19.701C21.153 20.468 20.467 21.154 19.7 20.971C18.294 20.634 16.386 20.086 15.766 19.467Z" />
	</Icon>
);

export default BrandKitIcon;