import React, { useContext } from "react"
import styled, { ThemeProvider, useTheme } from 'styled-components';
import { Link } from "gatsby"
import { interactions, themes } from '@simplate/styles';
import { m as motion, useViewportScroll, useTransform } from 'framer-motion';
import { useMedia } from 'react-use';

import { LayoutContext } from "context/Layout";

import Box from '@simplate/ui/Box';
import Mast from '@simplate/ui/Mast';

import Button from "./Button"
import Container from "./Container"
import Text from "./Text"

const Root = styled(motion.header)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
`;

const Wrapper = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: ${({ theme }) => theme.breakpoints.tabletSm}) {
		justify-content: space-between;
	}
`;

const Main = styled(Box)`
	display: flex;
	align-items: center;
	& > * + * {
		margin-left: ${({ theme }) => theme.space[12]}
	}
`;

const Menu = styled(Box)`
	display: flex;
	align-items: center;
	& > a + a {
		margin-left: ${({ theme }) => theme.space[9]}
	}
`;

const NavLink = styled(Text).attrs({
	as: Link
})`
	${({ theme }) => theme.textStyles.web.label.sm};
	&:not(.active) {
		${interactions};
	}
`;

const Actions = styled(Box)`
	display: flex;
	align-items: center;
	& > button + button {
		margin-left: ${({ theme }) => theme.space[8]}
	}
`;

const Header = ({ onSignUp }) => {
	const theme = useTheme();
	const isTablet = useMedia(`(min-width: ${theme.breakpoints.tabletSm})`);
	const { scrollY } = useViewportScroll();
	const color = theme.dark ? 'background' : 'surface';

	const range = isTablet ? [96, 160] : [24, 64]

	const backgroundColor = useTransform(scrollY, range, [theme.utils.colors.fade(theme.colors[color], 0), theme.utils.colors.fade(theme.colors[color], 1)]);
	const boxShadow = useTransform(scrollY, range, [`0px 1px 0px ${theme.utils.colors.fade(theme.colors.border, 0)}`, `0px 1px 0px ${theme.utils.colors.fade(theme.colors.border, 1)}`]);

	if (!isTablet) {
		return (
			<Root style={{ backgroundColor, boxShadow }}>
				<Wrapper height={10}>
					<Link to="/">
							<Mast />
						</Link>
				</Wrapper>
			</Root>
		);
	}
	return (
		<Root style={{ backgroundColor, boxShadow }}>
			<Wrapper height={12}>
				<Main>
					<Link to="/">
						<Mast />
					</Link>
					<Menu>
						<NavLink interaction="opacity" to="/teams/" activeClassName="active">
							For Teams
						</NavLink>
						<NavLink interaction="opacity" to="/creators/" activeClassName="active">
							For Creators
						</NavLink>
					</Menu>
				</Main>
				<Actions>
					{/* <Button variant="flat" size="sm"><Text>Sign in</Text></Button> */}
					<Button onClick={onSignUp} color="green" size="sm"><Text>Sign up for beta</Text></Button>
				</Actions>
			</Wrapper>
		</Root>
	);
}

const ThemedHeader = () => {
	const { setRequestModal, headerTheme } = useContext(LayoutContext);
	return (
		<ThemeProvider theme={themes[headerTheme]}>
			<Header onSignUp={() => setRequestModal(true)} />
		</ThemeProvider>
	)
};

export default ThemedHeader
