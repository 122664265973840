import styled from 'styled-components';
import { border, color, flexbox, grid, interactions, layout, shadow, space, styledConfig, zIndex } from '@simplate/styles';
import { overflow } from 'styled-system';

const Box = styled.div.withConfig(styledConfig)`
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
	position: relative;

	${border};
	${color};
	${grid};
	${interactions};
	${layout};
	${flexbox};
	${overflow};
	${space};
	${shadow.boxShadow};
	${zIndex};

	${
		({ gradient, theme }) => gradient ? `
			background-color: ${theme.colors[gradient]};
			background: ${theme.gradients[gradient]};
		` : ''
	}
`;

export default Box;