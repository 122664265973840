import React, { memo } from 'react';
import styled from 'styled-components';

import Box from '../Box';
import Logo from '../Logo';
import Text from '../Text';

const Root = styled(Box)`
	display: flex;
	align-items: center;
	user-select: none;

	& > ${Text} {
		margin-left: ${({ theme }) => theme.space[2]}
	}
`;

const Mast = memo(() => {
	return (
		<Root>
			<Logo />
			<Text 
				color="text" 
				fontFamily="logotype" 
				fontWeight="bold" 
				fontSize={3} 
				lineHeight={3}
			>
				Simplate
			</Text>
		</Root>
	)
});

export default Mast;