import * as utils from '../utils';

import breakpoints from './breakpoints';
import colors from './colors';
import gradients from './gradients';
import {textStyles, textPlaceholderStyles} from './text';
import modules from './modules';
import * as scales from './scales';
import shadowColors from './shadowColors';

export default {
	...scales,
	breakpoints,
	colors,
	gradients,
	modules,
	shadowColors,
	textStyles,
	textPlaceholderStyles,
	utils,
};