import React from 'react';
import Icon from '../Icon';

const ChangeOrderIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 24 24">
		<path className="primary" d="M11 7C6.58143 7 3 10.5814 3 15V18H1.695C1.078 18 0.767999 18.81 1.202 19.287L4.287 22.677C4.68 23.108 5.32 23.108 5.713 22.677L8.798 19.287C9.232 18.81 8.921 18 8.305 18H7V15C7 12.7906 8.79057 11 11 11H13C14.1046 11 15 10.1046 15 9C15 7.89543 14.1046 7 13 7H11Z" />
		<path className="secondary" d="M22.798 4.71301L19.713 1.32301C19.32 0.892006 18.68 0.892006 18.287 1.32301L15.202 4.71301C14.768 5.19001 15.079 6.00001 15.695 6.00001H17V9.00001C17 11.206 15.206 13 13 13H11C9.896 13 9 13.896 9 15C9 16.104 9.896 17 11 17H13C17.411 17 21 13.411 21 9.00001V6.00001H22.305C22.921 6.00001 23.232 5.19001 22.798 4.71301Z" />
	</Icon>
);

export default ChangeOrderIcon;