import React from 'react';
import Icon from '../Icon';

const ArchiveIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 24 24">
		<path className="secondary" d="M18 21H6C4.343 21 3 19.657 3 18V4C3 3.448 3.448 3 4 3H20C20.552 3 21 3.448 21 4V18C21 19.657 19.657 21 18 21Z" />
		<path className="primary" d="M14 8H10C9.448 8 9 7.552 9 7C9 6.448 9.448 6 10 6H14C14.552 6 15 6.448 15 7C15 7.552 14.552 8 14 8Z" />
	</Icon>
);

export default ArchiveIcon;