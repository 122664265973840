import React from 'react';
import Icon from '../Icon';

const HandoffIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 24 24">
		<path className="secondary" d="M21.6213 14.7686C21.0988 14.1043 20.1489 13.9903 19.4281 14.4146L15.7049 16.6029C16.1057 15.7929 15.8274 14.7951 15.0524 14.34L12.3134 12.7303C10.7043 11.784 8.83435 11.4171 6.99943 11.6863L3.49958 12.2006C2.71128 12.3171 2.73961 15.6163 3.66707 15.6154V17.4283L3.09376 17.6743C2.34462 17.9949 2.91543 20.1429 3.6679 20.1429C3.88123 20.1429 4.09872 20.1 4.30787 20.0109L5.78781 19.4537C6.60694 19.146 7.49857 19.1092 8.33854 19.3509L13.655 20.8766C14.2983 21.0609 14.9874 20.9734 15.5674 20.6323L21.1597 17.3452C22.0388 16.8292 22.2871 15.6146 21.6213 14.7686Z" />
		<path className="primary" d="M3.2504 21C2.5596 21 2.00046 20.4249 2.00046 19.7143V12C2.00046 11.2894 2.5596 10.7143 3.2504 10.7143C3.94121 10.7143 4.50035 11.2894 4.50035 12V19.7143C4.50035 20.4249 3.94121 21 3.2504 21Z" />
		<path className="primary" d="M20.2447 8.51914L20.243 8.514C20.2964 8.40086 20.333 8.27743 20.333 8.14286C20.333 7.77943 20.1114 7.47257 19.8005 7.34829L19.7989 7.34314L17.8331 6.85714V3.85714C17.8331 3.384 17.4598 3 16.9998 3C16.6707 3 16.3924 3.20057 16.2565 3.48514L16.2515 3.48429L13.7516 7.76486L13.7558 7.77343C13.7025 7.88657 13.6666 8.00914 13.6666 8.14286C13.6666 8.54571 13.9425 8.868 14.3066 8.95971L14.3149 8.97686L16.1665 9.42857V12.4286C16.1665 12.9017 16.5398 13.2857 16.9998 13.2857C17.329 13.2857 17.6073 13.0851 17.7431 12.7997L17.7448 12.8006L17.764 12.7689C17.7648 12.7663 17.7673 12.7646 17.7681 12.7611L20.2447 8.51914Z" />
	</Icon>
);

export default HandoffIcon;