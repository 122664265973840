import { createGlobalStyle } from "styled-components";
import { cssReset } from '@simplate/styles';

const GlobalStyles = createGlobalStyle`
	${cssReset};

	::selection {
		background-color: ${({ theme }) => theme.utils.colors.fade(theme.colors.primary, 0.24)};
	}

	* {
		box-sizing: border-box;
		outline: none;
	}

	html {
		height: 100%;
		overflow-x: hidden;
		-webkit-font-smoothing: antialiased;
	}

	body {
		margin: 0;
		background-color: ${({ theme }) => theme.dark ? theme.colors.background : theme.colors.surface};
		color: ${({ theme }) => theme.colors.text};
		overflow-x: hidden;
	}

	hr {
		margin: 0;
		border-color: ${({ theme }) => theme.colors.border};
		border-top: 1px solid ${({ theme }) => theme.colors.border};
		border-bottom: none;
		border-left: none;
		border-right: none;
	}

	.ReactModalPortal {
		z-index: 1;
	}
`;

export default GlobalStyles;