import styled from 'styled-components';
import { color, space, styledConfig, textPlaceholderStyles, textStyles, typography, variant } from '@simplate/styles';

import Placeholder from '../Placeholder';

const variants = ({ lineClamp }) => variant({
	variants: {
		clamped: {
			display: '-webkit-box',
			'-webkit-line-clamp': `${lineClamp || 1}`,
			'-webkit-box-orient': 'vertical',
			overflow: 'hidden',
		},
	}
});

const Text = styled.p.withConfig(styledConfig).attrs(props => ({
	as: !props.children ? Placeholder : undefined,
}))`
	font-family: ${({ theme }) => theme.fonts.ui}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: ${({ theme }) => theme.fontWeights.regular};
	${variants};
	${textStyles};
	${space};
	${color};
	${typography};

	&${Placeholder} {
		${textPlaceholderStyles}
	}
`;

Text.defaultProps = {
	as: 'p',
}

export default Text;