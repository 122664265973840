import React from 'react';
import Icon from '../Icon';

const VariationIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 80 80">
		<path d="M14.1667 0H65.8333C73.6667 0 80 6.49573 80 14.5299V65.4701C80 73.5043 73.6667 80 65.8333 80H14.1667C6.33333 80 0 73.5043 0 65.4701V14.5299C0 6.49573 6.33333 0 14.1667 0Z" fill="#00005B"/>
		<path d="M32.1335 47.8633H19.7335L17.2002 55.9317C17.1335 56.2394 16.8668 56.4445 16.5668 56.4104H10.3002C9.93349 56.4104 9.83349 56.2052 9.93349 55.795L20.6668 24.2394C20.7668 23.8975 20.8668 23.5898 21.0002 23.1796C21.1335 22.4616 21.2002 21.7095 21.2002 20.9574C21.1668 20.7864 21.3002 20.6155 21.4668 20.5813H21.5668H30.1002C30.3335 20.5813 30.5002 20.6839 30.5335 20.8548L42.7002 55.7266C42.8002 56.1027 42.7002 56.2736 42.3668 56.2736H35.4002C35.1668 56.3078 34.9335 56.1369 34.8668 55.8975L32.1335 47.6924V47.8633ZM21.6668 41.0599H30.1335C29.9335 40.342 29.6668 39.4873 29.3668 38.5984C29.0668 37.6753 28.7668 36.6839 28.4668 35.6582C28.1335 34.5984 27.8335 33.5727 27.5002 32.5129C27.1668 31.4531 26.8668 30.4616 26.6002 29.4702C26.3335 28.5129 26.1002 27.624 25.8668 26.8035H25.8002C25.5002 28.2736 25.1335 29.7437 24.6668 31.2138C24.1668 32.8548 23.6668 34.5642 23.1335 36.2736C22.7002 38.0172 22.1668 39.624 21.6668 41.0599V41.0599Z" fill="#9999FF"/>
		<path d="M62.3334 44.7865H51.7667C51.9001 45.8463 52.2334 46.9062 52.8001 47.8292C53.4001 48.7523 54.2334 49.4703 55.2334 49.8805C56.5667 50.4617 58.0334 50.7694 59.5001 50.7352C60.6667 50.701 61.8334 50.5985 62.9667 50.3591C64.0001 50.2224 65.0001 49.9489 65.9334 49.5728C66.1001 49.4361 66.2001 49.5044 66.2001 49.8463V55.0771C66.2001 55.2138 66.1667 55.3506 66.1334 55.4874C66.0667 55.5899 66.0001 55.6583 65.9001 55.7267C64.8334 56.2053 63.7334 56.5472 62.5667 56.7523C61.0001 57.06 59.4334 57.1968 57.8334 57.1626C55.3001 57.1626 53.1667 56.7523 51.4334 55.966C49.8001 55.248 48.3667 54.1198 47.2334 52.7181C46.1667 51.3848 45.4001 49.8805 44.9334 48.2395C44.4667 46.6327 44.2334 44.9574 44.2334 43.248C44.2334 41.4019 44.5001 39.5899 45.0667 37.8121C45.6001 36.1027 46.4334 34.5301 47.5667 33.1284C48.6667 31.7609 50.0334 30.6668 51.6001 29.8805C53.1667 29.0942 55.0334 28.8207 57.1667 28.8207C58.9334 28.7865 60.7001 29.1284 62.3334 29.8805C63.7001 30.4959 64.9001 31.419 65.8334 32.6156C66.7001 33.778 67.4001 35.0771 67.8334 36.513C68.2667 37.8805 68.4667 39.2822 68.4667 40.6839C68.4667 41.5044 68.4334 42.2224 68.4001 42.872C68.3334 43.5215 68.3001 44.0002 68.2667 44.3079C68.2334 44.5472 68.0334 44.7523 67.8001 44.7523C67.6001 44.7523 67.2334 44.7865 66.7001 44.8207C66.1667 44.8891 65.5334 44.9232 64.7667 44.9232C64.0001 44.9232 63.2001 44.7865 62.3334 44.7865V44.7865ZM51.7667 39.795H58.8001C59.6667 39.795 60.3001 39.795 60.7001 39.7609C60.9667 39.7267 61.2334 39.6583 61.4667 39.4874V39.1455C61.4667 38.701 61.4001 38.2908 61.2667 37.8805C60.6667 35.966 58.9001 34.6668 56.9334 34.7352C55.1001 34.6327 53.3667 35.6241 52.5001 37.3335C52.1001 38.1198 51.8667 38.9403 51.7667 39.795Z" fill="#9999FF"/>
	</Icon>
);

export default VariationIcon;