import { get, system, variant } from 'styled-system';

export const textStyles = ({ theme, textStyle }) => get(theme.textStyles, textStyle);
export const textPlaceholderStyles = ({ theme, textStyle }) => get(theme.textPlaceholderStyles, textStyle);

export const wrapperBorderRadius = system({
	borderRadius: {
		property: 'borderRadius',
		scale: 'radii',
		transform: (v, scale) => `calc(${scale[v]} + 0.2rem)`,
	},
});

export const placeholderColor = system({
	placeholderColor: {
		property: 'color',
		scale: 'colors',
	},
});

export const fill = system({
	fill: {
		property: 'fill',
		scale: 'colors',
	},
})

export const itemGap = system({
	gapBottom: {
		property: 'marginBottom',
		scale: 'space',
	},
	gapLeft: {
		property: 'marginLeft',
		scale: 'space',
	},
	gapRight: {
		property: 'marginRight',
		scale: 'space',
	},
	gapTop: {
		property: 'marginTop',
		scale: 'space',
	},
});

export const selectable = ({ selected, theme }) => selected ? `
	background-color: ${theme.utils.colors.fade(theme.colors.primary, 0.04)} !important;
	box-shadow: 0px 0px 0px 1px ${theme.colors.primary}, 0px 1px 4px ${theme.utils.colors.fade(theme.colors.primary, 0.16)};
` : null;

export const interactions = (props) => {
	const { active, color = 'text', disabled, theme } = props;

	const hoverColor = theme.utils.colors.fade(theme.colors[color], .04);
	const activeColor = theme.utils.colors.fade(theme.colors[color], .08);
	
	return disabled ? {} : variant({
		prop: 'interaction',
		variants: {
			highlight: {
				cursor: disabled ? 'inherit' : 'pointer',
				transition: ({ easing }) => `120ms background ${easing.move}`,
				backgroundColor: active ? activeColor : 'transparent',
				// boxShadow: 'unset',
				'&:hover': {
					backgroundColor: active ? activeColor : hoverColor
				},
				'&:active': {
					backgroundColor: activeColor
				},
			},
			bump: {
				cursor: disabled ? 'inherit' : 'pointer',
				boxShadow: 1,
				transition: ({ easing }) => `80ms box-shadow ${easing.move}, 160ms transform ${easing.move}`,
				'&:hover': {
					boxShadow: 3,
					transform: 'translate3d(0px, -1px, 0px)',
				},
				'&:active': {
					boxShadow: 0,
					transform: 'translate3d(0px, 0px, 0px) scale(0.99)',
				}
			},
			opacity: {
				cursor: disabled ? 'inherit' : 'pointer',
				transition: ({ easing }) => `120ms opacity ${easing.move}`,
				opacity: active ? 1 : 0.56,
				'&:hover': {
					opacity: 1,
				},
				'&:active': {
					opacity: 0.32,
				},
			}
		}
	})
}