import React from 'react';
// import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

// const Root = styled.div`
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: -200%;
// 	z-index: -1;
// 	overflow-x: hidden;

// 	& > div {
// 		position: absolute;
// 		width: 512px;
// 		height: 512px;
// 		filter: blur(264px);
// 		border-radius: 50%;
// 		will-change: background;

// 		&:first-of-type {
// 			opacity: ${({ theme }) => theme.dark ? 0.3 : 0.4};
// 			top: 7%;
// 			left: -10%;
// 			background-color: ${({ theme }) => theme.colors.primary};
// 		}
// 		&:last-of-type {
// 			opacity: ${({ theme }) => theme.dark ? 0.2 : 0.3};
// 			top: -5%;
// 			right: -10%;
// 			background-color: ${({ theme }) => theme.colors.secondary};
// 		}

// 		@media (min-width: ${({ theme }) => theme.breakpoints.tabletSm}) {
// 			width: 872px;
// 			height: 872px;
// 			filter: blur(548px);

// 			&:first-of-type {
// 				top: 10%;
// 				left: -10%;
// 			}
// 			&:last-of-type {
// 				top: -10%;
// 				right: -15%;
// 			}
// 		}
// 	}
// `;
const style = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
}

const HeroBubbles = () => (
	<StaticImage style={style} placeholder="none" src="../images/background.png" />
)

export default HeroBubbles;