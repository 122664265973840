import { css } from 'styled-components';

const label = {
	xs: css`
		font-size: ${({ theme }) => theme.fontSizes[1]};
		line-height: ${({ theme }) => theme.lineHeights[3]};
		font-weight: ${({ theme }) => theme.fontWeights.semibold};
	`,
	sm: css`
		font-size: ${({ theme }) => theme.fontSizes[2]};
		line-height: ${({ theme }) => theme.lineHeights[3]};
		font-weight: ${({ theme }) => theme.fontWeights.semibold};
	`,
	md: css`
		font-size: ${({ theme }) => theme.fontSizes[3]};
		line-height: ${({ theme }) => theme.lineHeights[3]};
		font-weight: ${({ theme }) => theme.fontWeights.semibold};
	`,
	lg: css`
		font-size: ${({ theme }) => theme.fontSizes[3]};
		line-height: ${({ theme }) => theme.lineHeights[3]};
		font-weight: ${({ theme }) => theme.fontWeights.semibold};
		
		@media (min-width: ${({ theme }) => theme.breakpoints.mobileXl}) {
			font-size: ${({ theme }) => theme.fontSizes[4]};
			line-height: ${({ theme }) => theme.lineHeights[4]};
		}
	`,
	xl: css`
		font-size: ${({ theme }) => theme.fontSizes[3]};
		line-height: ${({ theme }) => theme.lineHeights[3]};
		font-weight: ${({ theme }) => theme.fontWeights.semibold};
		
		@media (min-width: ${({ theme }) => theme.breakpoints.mobileXl}) {
			font-size: ${({ theme }) => theme.fontSizes[4]};
			line-height: ${({ theme }) => theme.lineHeights[4]};
		}

		@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
			font-size: ${({ theme }) => theme.fontSizes[5]};
			line-height: ${({ theme }) => theme.lineHeights[5]};
		}
	`,
};

const textStyles = {
	text: {
		sm: css`
			font-size: ${({ theme }) => theme.fontSizes[2]};
			line-height: ${({ theme }) => theme.lineHeights[3]};
			font-weight: ${({ theme }) => theme.fontWeights.semibold};
			color: ${({ theme }) => theme.colors.text};
		`,
		md: css`
			font-size: ${({ theme }) => theme.fontSizes[3]};
			line-height: ${({ theme }) => theme.lineHeights[3]};
			font-weight: ${({ theme }) => theme.fontWeights.semibold};
			color: ${({ theme }) => theme.colors.text};
		`,
		lg: css`
			font-size: ${({ theme }) => theme.fontSizes[6]};
			line-height: ${({ theme }) => theme.lineHeights[8]};
			font-weight: ${({ theme }) => theme.fontWeights.semibold};
			color: ${({ theme }) => theme.colors.text};
		`,
	},
	subtext: {
		sm: css`
			font-size: ${({ theme }) => theme.fontSizes[0]};
			line-height: ${({ theme }) => theme.lineHeights[0]};
			font-weight: ${({ theme }) => theme.fontWeights.regular};
			color: ${({ theme }) => theme.colors.altText};
		`,
		md: css`
			font-size: ${({ theme }) => theme.fontSizes[1]};
			line-height: ${({ theme }) => theme.lineHeights[3]};
			font-weight: ${({ theme }) => theme.fontWeights.regular};
			color: ${({ theme }) => theme.colors.altText};
		`,
		lg: css`
			font-size: ${({ theme }) => theme.fontSizes[3]};
			line-height: ${({ theme }) => theme.lineHeights[5]};
			font-weight: ${({ theme }) => theme.fontWeights.regular};
			color: ${({ theme }) => theme.colors.altText};
		`,
	},
	subheading: css`
		color: ${({ theme }) => theme.colors.altText};
		font-size: ${({ theme }) => theme.fontSizes[0]};
		line-height: ${({ theme }) => theme.lineHeights[3]};
		font-weight: ${({ theme }) => theme.fontWeights.semibold};
		user-select: none;
	`,
	input: {
		helper: css`
			font-size: ${({ theme }) => theme.fontSizes[0]};
			line-height: ${({ theme }) => theme.lineHeights[3]};
			font-weight: ${({ theme }) => theme.fontWeights.medium};
		`,
		label: css`
			font-size: ${({ theme }) => theme.fontSizes[1]};
			line-height: ${({ theme }) => theme.lineHeights[3]};
			font-weight: ${({ theme }) => theme.fontWeights.medium};
			color: ${({ theme }) => theme.colors.text};
		`,
		placeholder: css`
			font-size: ${({ theme }) => theme.fontSizes[1]};
			line-height: ${({ theme }) => theme.lineHeights[3]};
			font-weight: ${({ theme }) => theme.fontWeights.medium};
			color: ${({ theme }) => theme.colors.altText};
		`,
		value: css`
			font-size: ${({ theme }) => theme.fontSizes[1]};
			line-height: ${({ theme }) => theme.lineHeights[3]};
			font-weight: ${({ theme }) => theme.fontWeights.medium};
			color: ${({ theme }) => theme.colors.text};
		`,
	},
	label,
	web: {
		title: {
			xs: css`
				font-size: ${({ theme }) => theme.fontSizes[5]};
				line-height: ${({ theme }) => theme.lineHeights[6]};
				font-weight: ${({ theme }) => theme.fontWeights.semibold};

				@media (min-width: ${({ theme }) => theme.breakpoints.mobileXl}) {
					font-size: ${({ theme }) => theme.fontSizes[6]};
					line-height: ${({ theme }) => theme.lineHeights[7]};
				}

				@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
					font-size: ${({ theme }) => theme.fontSizes[7]};
					line-height: ${({ theme }) => theme.lineHeights[8]};
				}
			`,
			sm: css`
				font-size: ${({ theme }) => theme.fontSizes[7]};
				line-height: ${({ theme }) => theme.lineHeights[8]};
				font-weight: ${({ theme }) => theme.fontWeights.bold};

				@media (min-width: ${({ theme }) => theme.breakpoints.mobileXl}) {
					font-size: ${({ theme }) => theme.fontSizes[8]};
					line-height: ${({ theme }) => theme.lineHeights[9]};
				}


				@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
					font-size: ${({ theme }) => theme.fontSizes[9]};
					line-height: ${({ theme }) => theme.lineHeights[10]};
				}
			`,
			md: css`
				font-size: ${({ theme }) => theme.fontSizes[8]};
				line-height: ${({ theme }) => theme.lineHeights[9]};
				font-weight: ${({ theme }) => theme.fontWeights.bold};

				@media (min-width: ${({ theme }) => theme.breakpoints.mobileXl}) {
					font-size: ${({ theme }) => theme.fontSizes[10]};
					line-height: ${({ theme }) => theme.lineHeights[11]};
				}

				@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
					font-size: ${({ theme }) => theme.fontSizes[11]};
					line-height: ${({ theme }) => theme.lineHeights[12]};
				}
			`,
			lg: css`
				font-size: ${({ theme }) => theme.fontSizes[9]};
				line-height: ${({ theme }) => theme.lineHeights[10]};
				font-weight: ${({ theme }) => theme.fontWeights.bold};

				@media (min-width: ${({ theme }) => theme.breakpoints.mobileLg}) {
					font-size: ${({ theme }) => theme.fontSizes[10]};
					line-height: ${({ theme }) => theme.lineHeights[11]};
				}

				@media (min-width: ${({ theme }) => theme.breakpoints.mobileXl}) {
					font-size: ${({ theme }) => theme.fontSizes[12]};
					line-height: ${({ theme }) => theme.lineHeights[13]};
				}

				@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
					font-size: ${({ theme }) => theme.fontSizes[14]};
					line-height: ${({ theme }) => theme.lineHeights[15]};
				}
			`,
		},
		text: {
			xs: css`
				font-size: ${({ theme }) => theme.fontSizes[1]};
				line-height: ${({ theme }) => theme.lineHeights[4]};
				font-weight: ${({ theme }) => theme.fontWeights.regular};

				@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
					font-size: ${({ theme }) => theme.fontSizes[3]};
					line-height: ${({ theme }) => theme.lineHeights[5]};
				}
			`,
			sm: css`
				font-size: ${({ theme }) => theme.fontSizes[2]};
				line-height: ${({ theme }) => theme.lineHeights[5]};
				font-weight: ${({ theme }) => theme.fontWeights.medium};
				
				@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
					font-size: ${({ theme }) => theme.fontSizes[5]};
					line-height: ${({ theme }) => theme.lineHeights[7]};
				}
			`,
			md: css`
				font-size: ${({ theme }) => theme.fontSizes[3]};
				line-height: ${({ theme }) => theme.lineHeights[6]};
				font-weight: ${({ theme }) => theme.fontWeights.medium};

				@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
					font-size: ${({ theme }) => theme.fontSizes[6]};
					line-height: ${({ theme }) => theme.lineHeights[8]};
				}
			`,
			lg: css`
				font-size: ${({ theme }) => theme.fontSizes[4]};
				line-height: ${({ theme }) => theme.lineHeights[7]};
				font-weight: ${({ theme }) => theme.fontWeights.medium};
				
				@media (min-width: ${({ theme }) => theme.breakpoints.mobileXl}) {
					font-size: ${({ theme }) => theme.fontSizes[6]};
					line-height: ${({ theme }) => theme.lineHeights[8]};
					font-weight: ${({ theme }) => theme.fontWeights.semibold};
				}

				@media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
					font-size: ${({ theme }) => theme.fontSizes[7]};
					line-height: ${({ theme }) => theme.lineHeights[9]};
					font-weight: ${({ theme }) => theme.fontWeights.semibold};
				}
			`,
		},
		label,
	}
};

const textPlaceholderStyles = {
	text: {
		sm: css`
			width: ${({ theme }) => theme.fontSizes[15]};
			height: ${({ theme }) => theme.lineHeights[3]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
		md: css`
			width: ${({ theme }) => theme.fontSizes[15]};
			height: ${({ theme }) => theme.lineHeights[3]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
		lg: css`
			width: ${({ theme }) => theme.sizes[17]};
			height: ${({ theme }) => theme.lineHeights[8]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
	},
	subtext: {
		sm: css`
			width: ${({ theme }) => theme.fontSizes[10]};
			height: ${({ theme }) => theme.lineHeights[0]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
		md: css`
			width: ${({ theme }) => theme.fontSizes[10]};
			height: ${({ theme }) => theme.lineHeights[3]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
		lg: css`
			width: ${({ theme }) => theme.fontSizes[10]};
			height: ${({ theme }) => theme.lineHeights[5]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
	},
	subheading: css`
		width: ${({ theme }) => theme.fontSizes[10]};
		height: ${({ theme }) => theme.lineHeights[3]};
		border-radius: ${({ theme }) => theme.radii.sm};
	`,
	input: {
		helper: css`
			width: ${({ theme }) => theme.fontSizes[10]};
			height: ${({ theme }) => theme.lineHeights[3]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
		label: css`
			width: ${({ theme }) => theme.fontSizes[10]};
			height: ${({ theme }) => theme.lineHeights[3]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
		placeholder: css`
			width: ${({ theme }) => theme.fontSizes[10]};
			height: ${({ theme }) => theme.lineHeights[3]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
		value: css`
			width: ${({ theme }) => theme.fontSizes[10]};
			height: ${({ theme }) => theme.lineHeights[3]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
	},
	button: {
		label: css`
			width: ${({ theme }) => theme.fontSizes[10]};
			height: ${({ theme }) => theme.lineHeights[3]};
			border-radius: ${({ theme }) => theme.radii.sm};
		`,
	}
};

export {
	textStyles,
	textPlaceholderStyles,
};
