import React, { forwardRef } from 'react';
import styled from 'styled-components';

import Box from '../Box';
import InputWrapper from '../internal/InputWrapper';

const IconWrapper = styled(Box)`
	display: flex;
	align-items: center;
`;

const Root = styled(Box)`
	display: grid;
	grid-template-columns: ${({ icon }) => icon ? `min-content 1fr min-content` : `1fr min-content`};
	background-color: ${({ theme }) => theme.utils.colors.fade(theme.colors.text, 0.08)};
	border-radius: ${({ theme }) => theme.radii.sm};
	border: 1px solid transparent;

	/** Leading Icon size */
	& > ${IconWrapper} svg {
		height: ${({ size, theme }) => theme.sizes[3]};
		width: ${({ size, theme }) => theme.sizes[3]};
	}
	
	/** Trailing Icon size */
	& > svg {
		width: ${({ theme }) => theme.fontSizes[5]};
		height: ${({ theme }) => theme.fontSizes[5]};
	}

	&:hover {
		background-color: ${({ theme }) => theme.utils.colors.fade(theme.colors.text, 0.12)};
	}
	
	&:focus-within {
		background-color: ${({ theme }) => theme.utils.colors.fade(theme.colors.text, 0.08)};
		border: 1px solid ${({ error, theme }) => theme.colors[error ? 'red' : 'primary']};
		box-shadow: 0px 1px 4px ${({ error, theme }) => theme.utils.colors.fade(theme.colors[error ? 'red' : 'primary'], 0.16)};
	}

	& input {
		padding: ${({ icon, theme }) => `${theme.space.sm} ${theme.space[3]} ${theme.space.sm} ${theme.space[icon ? 2 : 3]}`};
		font-family: ${({ theme }) => theme.fonts.ui};
		${({ theme }) => theme.textStyles.input.value};
		color: ${({ theme }) => theme.colors.text};
		background-color: transparent;
		border: 0;
		outline: 0;

		&::placeholder {
			${({ theme }) => theme.textStyles.input.placeholder};
		}
	}
`;

const TextInput = forwardRef(({
	autoFocus,
	autoComplete = 'off',
	className,
	children,
	disabled,
	error,
	icon,
	iconPadding = 2,
	label,
	name,
	onBlur,
	onChange,
	onClick,
	onFocus,
	onKeyDown,
	onKeyPress,
	placeholder,
	type = 'text',
	value,
	variant
}, ref) => (
	<InputWrapper className={className}  error={error} label={label} name={name} variant={variant}>
		<Root ref={ref} onClick={onClick} icon={icon} error={error}>
			{icon ? (
				<IconWrapper paddingLeft={iconPadding}>
					{icon}
				</IconWrapper>
			) : null}
			<input 
				autoFocus={autoFocus} 
				autoComplete={autoComplete} 
				disabled={disabled} 
				name={name} 
				onBlur={onBlur} 
				onChange={onChange} 
				onFocus={onFocus} 
				onKeyDown={onKeyDown}
				onKeyPress={onKeyPress}
				placeholder={placeholder} 
				type={type} 
				value={value} 
			/>
			{children}
		</Root>
	</InputWrapper>
));

export default TextInput;
