import React from "react"
import styled from 'styled-components';

import Box from '@simplate/ui/Box';
import Mast from '@simplate/ui/Mast';
import TextGroup from '@simplate/ui/TextGroup';

import Container from "./Container"
import Text from "./Text"

const Root = styled(Box).attrs({
	as: 'footer'
})`
	box-shadow: 0px -1px 0px ${({ theme }) => theme.colors.border};
	display: flex;
	align-items: stretch;
	background-color: ${({  theme }) => theme.dark ? theme.colors.background : theme.colors.surface};
`;

const Wrapper = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Footer = () => (
	<Root paddingY={8}>
		<Wrapper>
			<TextGroup space={4}>
				<Mast />
				<Text textStyle="text.xs" opacity={0.5}>© Copyright {new Date().getFullYear()}, Simplate Inc. · All rights reserved.</Text>
			</TextGroup>
		</Wrapper>
	</Root>
);

export default Footer
