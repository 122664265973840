import React from 'react';
import Icon from '../Icon';

const CloseCircleIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 24 24">
		<path className='secondary' d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
		<path className="primary" d="M14.8 16.2L7.8 9.2C7.4 8.8 7.4 8.2 7.8 7.8C8.2 7.4 8.8 7.4 9.2 7.8L16.2 14.8C16.6 15.2 16.6 15.8 16.2 16.2C15.8 16.6 15.2 16.6 14.8 16.2Z" />
		<path className="primary" d="M7.8 14.8L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.8 16.2 9.2L9.2 16.2C8.8 16.6 8.2 16.6 7.8 16.2C7.4 15.8 7.4 15.2 7.8 14.8Z" />
	</Icon>
);

export default CloseCircleIcon;