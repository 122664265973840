/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from 'styled-components';
import { themes } from '@simplate/styles';
import { domAnimation, LazyMotion } from 'framer-motion';
import ReactModal from 'react-modal';

import GlobalStyles from "styles/GlobalStyles";

import LayoutProvider from "context/Layout";
import RequestBetaModal from "components/RequestBetaModal"

import Toast from '@simplate/ui/Toast';

import Header from "./header"
import HeroBubbles from "./HeroBubbles"
import Footer from "./footer"

ReactModal.setAppElement('#___gatsby');

const Layout = ({ children, theme = 'light' }) => {
  return (
    <LazyMotion features={domAnimation}>
		<ThemeProvider theme={themes[theme]}>
			<LayoutProvider initialTheme={theme}>
				<Header />
				<HeroBubbles />
				{children}
				<Footer />
				<GlobalStyles />
				<RequestBetaModal />
				<Toast />
			</LayoutProvider>
		</ThemeProvider>
	</LazyMotion>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
