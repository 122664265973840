export const borderWidths = [
	'0.125rem',
	'0.25rem',
	'0.375rem',
	'0.5rem',
	'0.625rem', 
	'0.75rem',
	'0.875rem',
	'1rem',
];

export const easing = {
	ping: "cubic-bezier(.79, 0, .27, .92)",
	move: "cubic-bezier(.47, .01, .33, .94)",
	snapPing: "cubic-bezier(.85, -0.17, .27, .92)"
}

export const fonts = {
	logotype: 'sofia-pro',
	text: 'sofia-pro',
	title: 'Basier Circle',
	ui: 'Basier Circle'
};

export const fontSizes = [
	'0.75rem',
	'0.8125rem',
	'0.875rem',
	'1rem',
	'1.125rem',
	'1.25rem',
	'1.5rem',
	'1.75rem',
	'2rem',
	'2.5rem',
	'3rem',
	'3.5rem',
	'4rem',
	'4.5rem',
	'5rem',
	'5.5rem'
];

fontSizes.sm = '0.625rem';

export const lineHeights = fontSizes;

export const fontWeights = {
	'regular': 400,
	'medium': 500,
	'semibold': 600,
	'bold': 700,
};

export const radii = [
	'0.375rem', 
	'0.5rem', 
	'0.75rem', 
	'1rem', 
	'1.25rem',
	'1.5rem',
	'1.75rem',
	'2rem',
	'2.5rem',
	'3rem',
	'3.5rem',
	'4rem',
	'4.5rem',
	'5rem',
	'5.5rem',
	'6rem',
	'6.4rem'
];

radii.sm = '0.25rem';
radii['squircle'] = '33.3333333333%';
radii['circle'] = '50%';

export const sizes = [
	'0rem',
	'0.5rem',
	'0.75rem',
	'1rem',
	'1.5rem',
	'1.75rem',
	'2rem',
	'2.5rem',
	'3rem',
	'3.5rem',
	'4rem',
	'4.5rem',
	'5rem',
	'6rem',
	'7.5rem',
	'9rem',
	'9.5rem',
	'12rem',
	'15rem',
	'16.5rem',
	'20rem',
	'25rem',
	'32rem',
	'40rem',
	'46rem',
	'50rem',
	'60rem', // This matches the designs up to 1280 - build at this size and then we can say from 16 up is LG/XL screen values
	'75rem',
	'85rem',
];

sizes.small = '0.25rem';
sizes.nav = '4rem';
sizes.spaceNav = '13.5rem';
sizes.inlineSidebar = '13.5rem';
sizes.contained = '50.5rem';
sizes.uploader = '29rem';
sizes.detailSidebar = ['23rem', '25rem', '29rem'];
sizes.finder = '64.5rem';

export const space = [
	'0rem',
	'0.25rem',
	'0.5rem',
	'0.75rem',
	'1rem',
	'1.25rem',
	'1.5rem',
	'1.75rem',
	'2rem',
	'2.5rem',
	'3rem',
	'3.5rem',
	'4rem',
	'5rem',
	'6rem',
	'7.5rem',
	'8rem',
	'10rem',
];

space.xs = '0.125rem';
space.sm = '0.375rem';
space.gutter = '1.5rem';

export const zIndices = [
	0,
	10,
	20,
	30,
	40,
	50,
	60,
	70,
	80,
	90,
	100,
];

// This containers key can be passed directly 
// to maxWidth so that aligns with breakpoints.js
// See components/Container.js in @simplate/simplate.io
export const containers = [
	'100%',
	'100%',
	'100%',
	'100%',
	'66rem',
	'66rem',
	'78rem'
];