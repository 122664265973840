import styled from 'styled-components';
import { variant } from 'styled-system';

import Box from '@simplate/ui/Box';

import Text from './Text';

const sizeVariants = variant({
	prop: 'size',
	variants: {
		sm: {
			height: 7,
			borderRadius: 1,
			paddingX: 4,
			[`& > ${Text}`]: {
				fontSize: 2, 
				lineHeight: 3,
				fontWeight: 'semibold'
			}
		},
		md: {
			height: 8,
			borderRadius: 1,
			paddingX: 5,
			[`& > ${Text}`]: {
				fontSize: 4, 
				lineHeight: 4,
				fontWeight: 'semibold'
			}
		},
		lg: {
			height: [8, 9, 9, 10],
			borderRadius: [2, 3, 3, 3],
			paddingX: [5, 6, 6, 7],
			[`& > ${Text}`]: {
				fontSize: 5, 
				lineHeight: 5,
				fontWeight: 'semibold'
			}
		},
	}
});

const variants = ({ color = 'surface', theme }) => {
	const colorValue = color === 'surface' ? 'text' : color;
	return variant({
		variants: {
			flat: {
				backgroundColor: 'transparent',
				[`& > ${Text}`]: {
					color: colorValue
				},
			},
			raised: {
				backgroundColor: theme.colors[color],
				background: theme.gradients[color],
				boxShadow: `0px 85px 145px ${theme.utils.colors.fade(theme.shadowColors[color || 'green'], 0.05)}, 0px 35.511px 60.5775px ${theme.utils.colors.fade(theme.shadowColors[color || 'green'], 0.0359427)}, 0px 18.9859px 32.3876px ${theme.utils.colors.fade(theme.shadowColors[color || 'green'], 0.0298054)}, 0px 10.6433px 18.1562px ${theme.utils.colors.fade(theme.shadowColors[color || 'green'], 0.025)}, 0px 5.65259px 9.64265px ${theme.utils.colors.fade(theme.shadowColors[color || 'green'], 0.0201946)}, 0px 2.35217px 4.01252px ${theme.utils.colors.fade(theme.shadowColors[color || 'green'], 0.0140573)}`,
				[`& > ${Text}`]: {
					color: color === 'surface' ? 'text' : 'surface'
				},
			},
		}
	});
}

const Button = styled(Box).attrs(props => ({
	as: 'button',
	interaction: props.variant === 'flat' ? 'highlight' : 'bump',
}))`
	display: flex;
	align-items: center;
	justify-content: center;
	${sizeVariants};
	${variants};
	
	& > ${Text} {
		${({ size, theme }) => {
			if (size === 'sm') return theme.textStyles.web.label.xs;
			return theme.textStyles.web.label[size];
		}}
	}
`;

Button.defaultProps = {
	size: ["sm", "md", "md", "lg"],
	variant: 'raised',
}

export default Button;
