import styled from 'styled-components';

import Box from '../Box';

const Card = styled(Box)``;

Card.defaultProps = {
	backgroundColor: 'surface',
	borderRadius: 2
};

export default Card;