import React from 'react';
import Icon from '../Icon';

const LinkIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 24 24">
		<path className="secondary" d="M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z" />
		<path className="primary" d="M17 14C16.448 14 16 13.553 16 13V8H11C10.448 8 10 7.553 10 7C10 6.447 10.448 6 11 6H17C17.552 6 18 6.447 18 7V13C18 13.553 17.552 14 17 14Z" />
		<path className="primary" d="M10 15C9.74401 15 9.48801 14.902 9.29301 14.707C8.90201 14.316 8.90201 13.684 9.29301 13.293L16.293 6.29301C16.684 5.90201 17.316 5.90201 17.707 6.29301C18.098 6.68401 18.098 7.31601 17.707 7.70701L10.707 14.707C10.512 14.902 10.256 15 10 15Z" />
	</Icon>
);

export default LinkIcon;
