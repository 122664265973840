import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import ReactModal from 'react-modal';

import Card from '../Card';

ReactModal.defaultStyles = {};

const Overlay = styled.div`
	align-items: center;
    display: flex;
    justify-content: center;
    top: 0;
    right: 0;
	bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    z-index: 1000;
	padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[6]} 0 ${({ theme }) => theme.space[6]};
`;

const defaultRenderContentElement = (props, children) => <Card {...props}>{children}</Card>;
const defaultRenderOverlay = (props, contentElement) => (
	<Overlay {...props}>
		{contentElement}
	</Overlay>
)

const ModalBase = ({
	className,
	children,
	onClose,
	open,
	overlay = true,
	overlayBlur = true,
	renderContentElement = defaultRenderContentElement,
	renderOverlay = defaultRenderOverlay,
	shouldCloseOnEsc = true,
	shouldCloseOnOverlayClick = true,
	title,
}) => {
	const theme = useTheme();
	const style = useMemo(() => ({
		overlay: {
			backgroundColor: overlay && theme.dark ? theme.utils.colors.fade(theme.colors.black, !overlayBlur ? 0.64 : 0.2) : 'transparent' ,
			backdropFilter: overlay && overlayBlur ? 'blur(8px)' : undefined,
		},
	}), [overlay, theme]);

	return (
		<ReactModal
			className={className}
			isOpen={open}
			onRequestClose={onClose}
			contentLabel={title}
			contentElement={renderContentElement}
			overlayElement={renderOverlay}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
			shouldCloseOnEsc={shouldCloseOnEsc}
			style={style}
		>
			{children}
		</ReactModal>
	);
}

export default ModalBase;