import styled from 'styled-components';
import SimplateText from '@simplate/ui/Text';

const Text = styled(SimplateText).attrs(props => ({
	textStyle: props.textStyle ? `web.${props.textStyle}` : undefined
}))`
	color: ${({ color = 'text', theme }) => theme.colors[color]};
	font-family: ${({ theme }) => theme.fonts.text}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

	h1&, h2&, h3&, h4&, h5&, h6& {
		font-family: ${({ theme }) => theme.fonts.title}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	}

	${
		({ gradient, theme }) => gradient ? `
			background-color: ${theme.colors[gradient]};
			background: ${theme.gradients[gradient]};
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		` : ''
	}
`;

export default Text;