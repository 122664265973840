import styled from 'styled-components';
import { itemGap } from '@simplate/styles';
import { variant } from 'styled-system';

import Box from '../Box';
import Text from '../Text';

const variants = variant({
	prop: 'align',
	variants: {
		left: {
			[`${Text}`]: {
				textAlign: 'left',
			}
		},
		center: {
			[`${Text}`]: {
				textAlign: 'center',
			}
		},
		right: {
			[`${Text}`]: {
				textAlign: 'right',
			}
		},
	}
});

const TextGroup = styled(Box).attrs(props => ({
	gapTop: props.space,
}))`
	${variants};

	& > * + * {
		${itemGap};
	}
`;

TextGroup.defaultProps = {
	space: 1,
}

export default TextGroup;