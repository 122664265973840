import React, { useEffect, useMemo, useState } from 'react';

import LayoutContext from './LayoutContext';

const LayoutProvider = ({ children, initialTheme }) => {
	const [headerTheme, setHeaderTheme] = useState(initialTheme);
	const [requestModal, setRequestModal] = useState(false);
	const value = useMemo(() => ({
		headerTheme,
		setHeaderTheme,
		requestModal,
		setRequestModal,
	}), [headerTheme, requestModal]);

	useEffect(() => {
		setHeaderTheme(initialTheme);
	}, [initialTheme]);

	return (
		<LayoutContext.Provider value={value}>
			{children}
		</LayoutContext.Provider>
	);
};

export default LayoutProvider;