import React from "react";
import styled from "styled-components";
import { layout, opacity, variant } from 'styled-system';
import { fill } from '@simplate/styles';

import Placeholder from '../Placeholder';

const variants = variant({
	variants: {
		filled: {
			'& .primary': {
				fill: 'white !important',
			},
			'& .secondary': {
				opacity: 1,
			}
		},
		ghost: {
			'& .primary': {
				opacity: 1,
			},
			'& .secondary': {
				opacity: 0.35
			}
		}
	}
});

const Icon = styled.svg.attrs(props => ({
	as: !props.children ? Placeholder : undefined,
	fill: props.color
}))`
   ${layout};
   ${opacity};
   ${fill};
   ${variants};

    &${Placeholder} {
        border-radius: 50%;
    }
`;

Icon.defaultProps = {
  color: "currentColor",
  size: 4,
  variant: 'ghost',
  viewBox: "0 0 16 16",
};

export default Icon;
