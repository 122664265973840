import base from './base';

/** Theme Specific color overrides */
const colors = {...base.colors};

colors.background = colors.offWhite;
colors.surface = colors.white; 
colors.flatSurface = colors.onWhite;
colors.disabled = colors.dust;
colors.border = colors.chalk;
colors.shadow = colors.lightGray;
colors.text = colors.slate;
colors.altText = colors.lightGray;

const light = {
    ...base,
	colors,
	dark: false,
    name: 'light',
};

export { light };