import React, { useCallback, useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { toast } from 'react-hot-toast';

import { interactions, themes } from '@simplate/styles';

import Box from '@simplate/ui/Box';
import { CloseIcon } from '@simplate/ui/Icon/icons';
import Logo from '@simplate/ui/Logo';
import ModalBase from '@simplate/ui/internal/ModalBase';

import { LayoutContext } from 'context/Layout';

import Button from 'components/Button';
import Text from 'components/Text';
import TextField from 'components/TextField';

const Root = styled(ModalBase)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 0;
	background-color: ${({ theme }) => theme.colors.background};
	box-shadow: 0px 8px 40px ${({ theme }) => theme.utils.colors.fade(theme.colors.trueBlack, 0.24)};
	padding: ${({ theme }) => `${ theme.space[9]} ${ theme.space[3]}`};

	@media (min-width: ${({ theme }) => theme.breakpoints.mobileXl}) {
		border-radius: ${({ theme }) => theme.radii[4]};
		width: 100%;
		top: 50%;
		left: 50%;
		right: unset;
		bottom: unset;
		transform: translate3d(-50%, -50%, 0);
		max-width: ${({ theme }) => theme.sizes[22]};
		padding: ${({ theme }) => theme.space[9]};
	}
`;

const Wrapper = styled(Form)`
	display: grid;
	grid-template-rows: min-content 1fr;
`;

const CloseButton = styled.button`
	${interactions};
	align-self: center;
	position: absolute;
	top: ${({ theme }) => theme.space[9]};
	right: ${({ theme }) => theme.space[9]};
	z-index: 2;
`;

const Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	& > * + * {
		margin-top: ${({ theme }) => theme.space[4]};
	}
`;

const Content = styled(Box)`
	margin-top: ${({ theme }) => theme.space[9]};
	margin-bottom: ${({ theme }) => theme.space[9]};
`;

const initialValues = {
	name: {
		first: '',
		last: '',
	},
	email: '',
	whatDo: '',
}

const validationSchema = object().shape({
	firstName: string().required('Required'),
	lastName: string().required('Required'),
	email: string().email().required('Required'),
	whatDo: string().required('Required'),
});

const RequestBetaModal = () => {
	const { requestModal, setRequestModal } = useContext(LayoutContext);

	const onSubmit = useCallback(async (values) => {
		try {
			await fetch('https://submit.simplate.io/forms/beta', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				mode: 'cors',
				body: JSON.stringify({
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					message: values.whatDo,
				}),
			});
			setRequestModal(false);
			toast.success("We'll be in touch soon!")
		} catch (error) {
			toast.error(error.message)
		}
	}, [setRequestModal]);

	return (
		<ThemeProvider theme={themes.dark}>
			<Root onClose={() => setRequestModal(false)} open={requestModal} overlayBlur={false}>
				<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
					<Wrapper>
						<CloseButton type="button" onClick={() => setRequestModal(false)} interaction="opacity">
							<CloseIcon color="text" size={4} />
						</CloseButton>
						<Header>
							<Logo height={9} />
							<Text as="h4" textStyle="title.xs">
								Request Beta Access
							</Text>
							<Text textStyle="text.sm" color="altText" textAlign="center">
								We review requests on a case by case basis. Our team will be in touch soon.
							</Text>
						</Header>
						<Content display="grid" gridTemplateRows="min-content" gridGap={4}>
							<Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={4}>
								<Field component={TextField} name="firstName" placeholder="First Name" />
								<Field component={TextField} name="lastName" placeholder="Last Name" />
							</Box>
							<Field component={TextField} name="email" placeholder="Email" />
							<Field component={TextField} name="whatDo" placeholder="What do you do?" />
						</Content>
						<Button color="green" type="submit">
							<Text>Request Access</Text>
						</Button>
					</Wrapper>
				</Formik>
			</Root>
		</ThemeProvider>
	);
};

export default RequestBetaModal;