import React from 'react';
import Icon from '../Icon';

const VideosIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 24 24">
		<path className="secondary" d="M3 18C3 19.657 4.343 21 6 21H18C19.657 21 21 19.657 21 18V11C21 9.895 20.105 9 19 9H3V18Z" />
		<path className="secondary" d="M16.899 2.08498L5.14101 4.48098C3.51701 4.81198 2.46901 6.39698 2.80001 8.01998L3.00001 8.99998L20.637 5.40498L20.437 4.42498C20.107 2.80198 18.522 1.75398 16.899 2.08498Z" />
		<path className="primary" d="M5.14101 4.48103C3.51701 4.81203 2.46901 6.39703 2.80001 8.02003L3.00001 9.00003L4.96001 8.60103L7.10001 4.08203L5.14101 4.48103Z" />
		<path className="primary" d="M9.06001 3.68301L6.91901 8.20101L9.85901 7.60201L12 3.08301L9.06001 3.68301Z" />
		<path className="primary" d="M13.959 2.68396L11.819 7.20296L14.758 6.60296L16.899 2.08496L13.959 2.68396Z" />
		<path className="primary" d="M18.597 2.23706L16.718 6.20406L20.637 5.40506L20.437 4.42506C20.227 3.39306 19.509 2.59806 18.597 2.23706Z" />
	</Icon>
);

export default VideosIcon;