import React from 'react';
import Icon from '../Icon';

const TeamIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 24 24">
		<path className="secondary" d="M21 21H3C1.343 21 0 19.657 0 18C0 16.343 1.343 15 3 15H21C22.657 15 24 16.343 24 18C24 19.657 22.657 21 21 21Z" />
		<path className="secondary" d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" />
		<path className="secondary" d="M19.5 13C20.8807 13 22 11.8807 22 10.5C22 9.11929 20.8807 8 19.5 8C18.1193 8 17 9.11929 17 10.5C17 11.8807 18.1193 13 19.5 13Z" />
		<path className="secondary" d="M4.5 13C5.88071 13 7 11.8807 7 10.5C7 9.11929 5.88071 8 4.5 8C3.11929 8 2 9.11929 2 10.5C2 11.8807 3.11929 13 4.5 13Z" />
		<path className="primary" d="M15 21H9C6.791 21 5 19.209 5 17C5 14.791 6.791 13 9 13H15C17.209 13 19 14.791 19 17C19 19.209 17.209 21 15 21Z" />
	</Icon>
);

export default TeamIcon;