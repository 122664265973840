import colors from "./colors";
import gradients from "./gradients";
import shadowColors from "./shadowColors";
import { colors as colorUtils } from '../utils/colors';

const modules = {
	text: {
		name: 'Text',
		color: colors.yellow,
		gradient: gradients.yellow,
		shadow: `0px 2px 4px ${colorUtils.fade(shadowColors.yellow, 0.32)}`
	},
	footage: {
		name: 'Footage',
		color: colors.purple,
		gradient: gradients.purple,
		shadow: `0px 2px 4px ${colorUtils.fade(shadowColors.purple, 0.32)}`
	},
	audio: {
		name: 'Audio',
		color: colors.orange,
		gradient: gradients.orange,
		shadow: `0px 2px 4px ${colorUtils.fade(shadowColors.orange, 0.32)}`
	},
	image: {
		name: 'Image',
		color: colors.red,
		gradient: gradients.red,
		shadow: `0px 2px 4px ${colorUtils.fade(shadowColors.red, 0.32)}`
	},
	logo: {
		name: 'Logo',
		color: colors.blue,
		gradient: gradients.blue,
		shadow: `0px 2px 4px ${colorUtils.fade(shadowColors.blue, 0.32)}`
	},
}

export default modules;