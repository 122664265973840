import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import Box from '../Box';
import Text from '../Text';

const layoutVariant = variant({
	variants: {
		vertical: {
			'& > * + *': {
				marginTop: 2,
			},
		},
		horizontal: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			'& > * + *': {
				marginLeft: 2,
			},
			[`& > ${Text}`]: {
				alignSelf: 'flex-start',
				marginTop: 1,
				minWidth: 11
			},
			[`& > *:not(${Text})`]: {
				flex: 1
			}
		}
	}
})

const Root = styled(Box)`
	display: flex;
	flex-direction: column;
	& > ${Text} {
		user-select: none;
	}
	${layoutVariant};
`

const InputWrapper = forwardRef(({ 
	children, 
	className, 
	error, 
	label, 
	onClick, 
	name, 
	variant = 'vertical' 
}, ref) => (
	<Root ref={ref} className={className} variant={variant} onClick={onClick}>
		{label ? (
			<Text htmlFor={name} as="label" textStyle="input.label">
				{label}
			</Text>
		) : null}
		{children}
		{error ? (
			<Text marginTop={1} color="red" textStyle="input.helper">
				{error}
			</Text>
		) : null}
	</Root>
));

export default InputWrapper;