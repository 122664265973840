import base from './base';

/** Theme Specific color overrides */
const colors = {...base.colors};

colors.background = colors.black;
colors.surface = colors.slate; 
colors.flatSurface = colors.onSlate;
colors.disabled = colors.ghost; 
colors.border = colors.darkGray;
colors.shadow = colors.trueBlack; 
colors.text = colors.white;
colors.altText = colors.gray;

const dark = {
    ...base,
    colors,
	dark: true,
    name: 'dark'
};

export { dark };