const colors = {
	green: '#41E488',
    purple: '#8957FF',
    orange: '#FC7540',
	blue: '#0066FF',
	teal: "#32E6ED",
	lightBlue: "#31A5FF",
	pink: "#F765BF",
    red: '#EA2A53',
    yellow: '#FFBB00',
    white: '#ffffff',
    offWhite: '#fcfcfc',
	chalk: "#f1f1f1",
	dust: '#f1f2f4',
    gray: '#989A9A',
	lightGray: '#575859',
	onWhite: "#FAFAFA",
	ghost: '#292A2A',
	darkGray: '#1f2021',
	onSlate: "#1C1D1F",
	slate: '#161718',
	black: '#111213',
	cinema: '#070708',
    trueBlack: '#000000',
};


colors.shadow = colors.trueBlack;
colors.primary = colors.green;
colors.secondary = colors.purple;

colors.info = colors.blue;
colors.error = colors.red;
colors.warning = colors.yellow;

colors.status = {
	Active: colors.purple,
	Draft: colors.yellow,
	Archived: colors.background
}

export default colors;