const shadowColors = {
	green: '#259C7B',
	blue: '#0472D2',
    purple: '#4700BD',
    red: '#BC102D',
    yellow: '#CC8700',
    orange: '#D13600',
    black: '#161718',
};

shadowColors.primary = shadowColors.green;
shadowColors.secondary = shadowColors.purple;

export default shadowColors;
