const breakpoints = [
	'24rem', // lg mobile
	'35rem', // xl mobile
	'60rem', // sm tablet
	'70rem', // tablet
	'75rem', // sm laptop
	'85rem', // lg laptop
	'100rem', // lg screen
	'128rem', // 4k
];

breakpoints.mobileLg = breakpoints[0];
breakpoints.mobileXl = breakpoints[1];
breakpoints.tabletSm = breakpoints[2];
breakpoints.tablet = breakpoints[3];
breakpoints.laptopSmall = breakpoints[4];
breakpoints.laptop = breakpoints[5];
breakpoints.desktop = breakpoints[6];
breakpoints.desktopLarge = breakpoints[7];

export default breakpoints