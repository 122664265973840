import styled, { keyframes } from "styled-components";
import { system } from 'styled-system';
import { nanoid } from 'nanoid';

import Box from '../Box';

export const animation = keyframes`
	
`;

const placeholderHeight = system({
	fontSize: {
		property: 'height',
		scale: 'fontSizes',
	},
	placeholderHeight: {
		property: 'height',
		scale: 'fontSizes',
	},
});

const placeholderWidth = system({
	placeholderWidth: {
		property: 'width',
		scale: 'sizes',
	},
});

const Placeholder = styled(Box).withConfig({
	shouldForwardProp: (prop) => !['placeholderWidth', 'placeholderHeight', 'placeholderId'].includes(prop)
}).attrs({
	children: null,
	placeholderId: nanoid(10), 
})`
	min-width: .25rem;
	${placeholderHeight};
	${placeholderWidth};
	animation: 1s placeholder-${({ placeholderId }) => placeholderId} linear infinite;

	@keyframes placeholder-${({ placeholderId }) => placeholderId} {
		0% {
			background-color: ${({ placeholderColor = 'text', theme }) =>
				theme.utils.colors.fade(theme.colors[placeholderColor], 0.02)};
		}
		50% {
			background-color: ${({ placeholderColor = 'text', theme }) =>
				theme.utils.colors.fade(theme.colors[placeholderColor], 0.08)};
		}
		100% {
			background-color: ${({ placeholderColor = 'text', theme }) =>
				theme.utils.colors.fade(theme.colors[placeholderColor], 0.02)};
		}
	}
`;


export default Placeholder;