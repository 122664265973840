import styled from 'styled-components';
import { itemGap } from '@simplate/styles';

import Box from '../Box';

const IconLabel = styled(Box)`
	display: flex;
	align-items: center;

	& > * + * {
		${itemGap};
	}
`;

export default IconLabel;
