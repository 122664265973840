import styled from 'styled-components';

import Box from '@simplate/ui/Box';

const Container = styled(Box).attrs(props => ({
	maxWidth: props.theme.containers,
	paddingX: [5, 5, 5, 5, 0],
	marginX:  'auto'
}))`
	width: 100%;
`;

export default Container;