import React, { memo, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import Icon from '../Icon';

const Logo = memo((props) => {
  const theme = useContext(ThemeContext);
  return (
    <Icon preserveAspectRatio='xMidYMid' viewBox="0 0 32 40" size="unset" height={4} {...props}>
		<path fillRule="evenodd" clipRule="evenodd" d="M12 27.78V23.2667C11.6091 23.1103 11.2296 22.936 10.8625 22.7441C10.1956 22.3952 9.57263 21.9875 9 21.53V27.78H10.8625H12Z" fill={theme.colors.primary}/>
		<path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M12 27.78V23.2667C11.6091 23.1103 11.2296 22.936 10.8625 22.7441C10.1956 22.3952 9.57263 21.9875 9 21.53V27.78H10.8625H12Z" fill="black"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M16 12.1271H26.2797C25.4289 7.24458 21.148 3.52078 16 3.52078C14.168 3.52202 12.5987 4.67706 11.986 6.25917C11.7964 6.77182 11.6923 7.2855 11.6923 7.82396C11.6923 10.1964 13.6294 12.1267 16 12.1271ZM26.4755 26.0147C26.4755 24.1982 25.9936 22.4088 25.1049 20.8313C23.2229 17.6435 19.7634 15.6568 16.0979 15.6479C12.3988 15.6568 9.31998 13.182 8.46154 9.87775C8.24421 9.18619 8.15007 8.50745 8.16783 7.82396C8.15007 7.54887 8.16334 7.29378 8.16783 7.04156C6.58493 8.86145 5.62238 11.2488 5.62238 13.8875C5.62238 16.9516 7.0038 19.784 9.24476 21.7115V27.7751H5.81818C6.62765 32.6675 10.9104 36.3814 16.0979 36.3814C21.7988 36.3814 26.4755 31.7332 26.4755 26.0147ZM30 13.8875V14.1809C30 15.0451 29.2994 15.7457 28.4352 15.7457H25.3007C26.4266 16.6767 27.375 17.8196 28.1399 19.0709C29.3513 21.2181 30 23.6259 30 26.1125C30 33.7526 23.72 40 16 40C8.28085 40 2 33.7526 2 26.1125V25.8191C2 24.9549 2.70058 24.2543 3.56479 24.2543H6.6993C3.77197 21.7079 2 17.9689 2 13.8875C2 10.2697 3.41467 6.81557 6.01399 4.20538C8.54737 1.58897 11.9769 0.0997743 15.6084 0C15.738 0.00288603 15.8698 0 16 0C23.72 0 30 6.24702 30 13.8875ZM11.8881 22.6556C11.8881 21.4523 13.1896 20.6993 14.2328 21.299L19.9059 24.5603C20.9524 25.1619 20.9524 26.6718 19.9059 27.2735L14.2328 30.5348C13.1896 31.1345 11.8881 30.3814 11.8881 29.1782V22.6556Z" fill={theme.colors.primary}/>
    </Icon>
  );
});

export default Logo;