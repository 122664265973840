const gradients = {
	green: "linear-gradient(170deg, #41E488 1.81%, #27B9A1 101.57%)",
	blue: "linear-gradient(170deg, #32E6ED 0%, #0066FF 100%)",
	purple: "linear-gradient(170deg, #C861FF 0%, #8957FF 100%)",
	red: "linear-gradient(170deg, #F765BF 0%, #EB3339 100%)",
	yellow: "linear-gradient(170deg, #FFD500 1.81%, #FF9900 101.57%)",
	orange: "linear-gradient(170deg, #FC9E40 0%, #FF5833 100%)",
	black: "linear-gradient(170deg, #393941 0%, #1D1D21 56.77%, #070708 100%)",
};

gradients.primary = gradients.green;
gradients.secondary = gradients.purple;

export default gradients;