import React from 'react';
import Icon from '../Icon';

const RevenueIcon = ({ className, color, size, variant }) => (
	<Icon className={className} color={color} size={size} variant={variant} viewBox="0 0 24 24">
		<path className="secondary" d="M5 4H19C20.657 4 22 5.343 22 7V17C22 18.657 20.657 20 19 20H5C3.343 20 2 18.657 2 17V7C2 5.343 3.343 4 5 4Z" />
		<path className="primary" d="M14.378 13.685C14.378 11.095 11.519 11.449 11.519 10.099C11.519 9.304 12.144 9.22 12.36 9.22C12.573 9.22 12.762 9.271 12.922 9.343C13.298 9.513 13.744 9.355 13.982 9.018C14.287 8.586 14.129 7.979 13.647 7.762C13.383 7.643 13.053 7.547 12.653 7.509V7.133C12.653 6.787 12.372 6.506 12.026 6.506C11.68 6.506 11.399 6.787 11.399 7.133V7.641C10.35 7.991 9.668 8.951 9.668 10.18C9.668 12.898 12.492 12.407 12.492 13.82C12.492 14.092 12.363 14.685 11.663 14.685C11.352 14.685 11.083 14.597 10.864 14.483C10.493 14.289 10.032 14.432 9.795 14.778L9.77 14.815C9.496 15.215 9.607 15.775 10.028 16.014C10.382 16.215 10.815 16.371 11.338 16.426V16.866C11.338 17.212 11.619 17.493 11.965 17.493C12.311 17.493 12.592 17.212 12.592 16.866V16.313C13.753 15.958 14.378 14.9 14.378 13.685Z" />
		<path className="primary" d="M19 4H18C18 6.209 19.791 8 22 8V7C22 5.343 20.657 4 19 4Z" />
		<path className="primary" d="M5 20H6C6 17.791 4.209 16 2 16V17C2 18.657 3.343 20 5 20Z" />
		<path className="primary" d="M5 4H6C6 6.209 4.209 8 2 8V7C2 5.343 3.343 4 5 4Z" />
		<path className="primary" d="M19 20H18C18 17.791 19.791 16 22 16V17C22 18.657 20.657 20 19 20Z" />
		<path className="primary" d="M18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13Z" />
		<path className="primary" d="M6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z" />
	</Icon>
);

export default RevenueIcon;