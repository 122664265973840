const STYLED_PROPS = [
	'active',
	'alignItems',
	'backgroundColor',
	'borderRadius',
	'color',
	'display',
	'fade',
	'fontFamily',
	'fontSize',
	'fontWeight',
	'gapTop',
	'gapRight',
	'gapBottom',
	'gapLeft',
	'gutter',
	'height',
	'hidePreview',
	'icon',
	'interaction',
	'lineHeight',
	'margin',
	'marginTop',
	'marginRight',
	'marginBottom',
	'marginLeft',
	'marginX',
	'marginY',
	'maxHeight',
	'maxWidth',
	'minWidth',
	'minHeight',
	'overflow',
	'overflowY',
	'overflowX',
	'secondaryIcon',
	'padding',
	'paddingTop',
	'paddingRight',
	'paddingBottom',
	'paddingLeft',
	'paddingX',
	'paddingY',
	'paragraph',
	'selectable',
	'ratio',
	'reverse',
	'space',
	'spacing',
	'size',
	'textStyle',
	'width',
	'variant',
];

const shouldForwardProp = (prop, defaultValidator) => !STYLED_PROPS.includes(prop);

const styledConfig = {
	shouldForwardProp,
};

export default styledConfig;